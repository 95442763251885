.cardbody{
    position: absolute;
    bottom: 0;
    background: linear-gradient(0deg, black, transparent);
    color: #fff;
    border-radius: 12px;
}

.cardimage{
    border-radius: 12px;
}
.main-title{
    color: #0A0908;
  }

  .thumbnail{
    width: 100%;
  }

  .card-title{
    word-break: break-all;
  }

  .customcardcss {
    line-height: 18px;
  }
  /* .card{
    width: 18rem;
  } */
  
  .editbtn {
    width: 100%;
    background: #fff ;
    color: #000;
    border: none;
    outline: none;
  }

  
  .editbtn:hover{
    width: 100%;
    background: #ffffffeb ;
    color: #000;
    border: none;
    outline: none;
  }
  .editbtn:focus {
    width: 100%;
    background: #ffffffeb ;
    color: #000;
    border: none;
    outline: none;
  }
  
  .deletebtn {
    width: 100%;
    background: #ffffff3d ;
    border: 1px solid #fff;
  }

  .deletebtn:hover {
    width: 100%;
    background: #ffffff3d ;
    border: 1px solid #fff;
  }

  .deletebtn:focus {
    width: 100%;
    background: #ffffffeb ;
    color: #000;
    border: 1px solid #fff;
   
  }

  .badgecss{
    float: right !important;
    background: #e9e9e9 !important;
    font-size: 14px;
    border-radius: 100px;
    color: #000;
  }
  .cardcol{
    padding: 14px;
  }

  .FormCss{
    background: #f7f7f7;
    padding: 20px;
    margin: 20px;
    border-radius: 12px;
  }