html,
body {
    margin: 0;
    padding: 0;
    background-color: #F7F7F7;
    color: #9e9ea4;
    font-family: 'Montserrat', sans-serif;
  }
  
  .icon {
    vertical-align: middle;
    line-height: 1px;
    font-size: 20px;
  }
  .icon_header {
    vertical-align: middle;
    line-height: 1px;
    font-size: 26px;
  }
  .icon, .icon_header {
    margin-right: 5px;
  }
  
  .close_icon {
    color: red;
    margin-left: 30px;
    margin-top: 10px;
    cursor: pointer;
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: 260px 1fr ;
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas:
      'sidebar header header header'
      'sidebar main main main';
    height: 100vh;
  }
  
  /* Header  */
  .header {
    grid-area: header;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px 0 30px;
    /* box-shadow: 0 6px 7px -3px rgba(0, 0, 0, 0.35); */
  }
  
  .menu-icon {
    display: none;
  }
  /* End Header  */
  
  /* Sidebar  */
  #sidebar {
    grid-area: sidebar;
    height: 100%;
    background-color: #0A0908;
    overflow-y: auto;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
  }
  
  .sidebar-title {
   
    text-align: center;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px 0px 30px;
    margin-bottom: 30px;
  }
  
  .sidebar-title > span {
    display: none;
  }
  
  .sidebar-brand {
    margin-top: 15px;
    font-size: 20px;
    font-weight: 700;
  }
  
  .sidebar-list {
    padding: 0;
    list-style-type: none;
  }
  
  .sidebar-list-item {
    padding: 20px 20px 20px 20px;
    font-size: 18px;
  }
  
  .sidebar-list-item:hover {
    background-color:#918C86;
    color: #fff;
    cursor: pointer;
  }
  
  .sidebar-list-item > a {
    text-decoration: none;
    color: #fff;
  }
  
  .sidebar-responsive {
    display: inline !important;
    position: absolute;
    /*
      we want the z-index of the sidebar higher so that
      the charts are not showing over the sidebar 
      on small screens
    */
    z-index: 12 !important;
  }

  .ARR{
    width: 120px;
  }
  
  /* End Sidebar  */
  
  
  /* Main  */  
  .main-container {
    grid-area: main;
    overflow-y: auto;
    padding: 20px 20px;
    color: rgba(255, 255, 255, 0.95);
  }
  
  .main-title {
    display: flex;
    justify-content: space-between;
  }
  
  .main-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 20px;
    margin: 15px 0;
  }
  
  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border: none;
    outline: none;
  }
  
  .card:first-child {
    /* background-color:#000000; */
    border-radius: 0;
    border: none;
  }
  
  .card:nth-child(2) {
    background-color: #ff6d00;
  }
  
  .card:nth-child(3) {
    background-color: #2e7d32;
  }
  
  .card:nth-child(4) {
    background-color: #d50000;
  }
  
  .card-inner {
  
  position: absolute;
    background: linear-gradient(0deg, black, transparent);
    bottom: 0;
    color: #fff;
    padding: 20px;
  }
  
  .card-inner > .card_icon {
    font-size: 25px;
  }
  
  .charts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 60px;
    height: 300px;

  }
  
  /* End Main  */
  
  
  
  /* Medium <= 992px */
    
  @media screen and (max-width: 992px) {
    /* .grid-container {
      grid-template-columns: 1fr;
      grid-template-rows: 0.2fr 3fr;
      grid-template-areas:
        'header'
        'main';
    } */
  
    /* #sidebar {
      display: none;
    } */
  
    /* .menu-icon {
      display: inline;
    } */
  
    /* .sidebar-title > span {
      display: inline;
    } */
  }
  /* Small <= 768px */
    
  @media screen and (max-width: 768px) {
    /* .main-cards {
      grid-template-columns: 1fr;
      gap: 10px;
      margin-bottom: 0;
    } */
  
    .charts {
      grid-template-columns: 1fr;
      margin-top: 30px;
    }
  }
  
  /* Extra Small <= 576px */
  
  @media screen and (max-width: 576px) {
    .hedaer-left {
      display: none;
    }
  }

  ::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }
  
  ::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: rgba(66, 66, 66, 0.2);
    border: 0px;
    background-clip: padding-box;
    border-radius: 5px;
  }
  
  nav {
    background-color: #232f3e;
    padding: 1rem 1rem;
  }
  
  #search_btn {
    background-color: #febd69;
  }
  
  .input-group {
    width: 90%;
    margin-right: 35%;
  }
  
  #search_field {
    height: 2.4rem;
    padding: 1rem;
  }
  
  #login_btn,
  #view_btn {
    background-color: #febd69;
    padding: 0.4rem 1.8rem;
    font-family: calibri;
  }
  
  #cart {
    font-size: 1rem;
    color: white;
  }
  
  #cart_count {
    background-color: #febd69;
    padding: 0.2rem 0.5rem;
    border-radius: 0.2rem;
    color: black;
    font-weight: bold;
    font-size: 0.9rem;
  }
  
  /* Home page */
  #products_heading {
    margin-top: 1.8rem;
  }

  .card-body {
    width: 100%;
  }
  
  /* .card {
    height: 100%;
  }
  
  .card-title a {
    color: #2e2e2e;
  }
  
  .card-title a:hover {
    color: #fa9c23;
    text-decoration: none;
  }
  
  .card-body {
    padding-left: 0;
  }
  
  .card-text {
    font-size: 1.4rem;
  }
  
  #view_btn {
    background-color: #fa9c23;
    color: white;
  }
  
  .card-img-top {
    width: 200px;
    height: 150px;
  } */
  
  .ratings {
    font-size: 1.2rem;
    color: #fdcc0d;
  }
  
  #no_of_reviews {
    font-size: 0.85rem;
    color: grey;
    margin-left: 0.5rem;
  }
  
  /* Footer */
  footer {
    margin-top: 8rem;
    color: grey;
    bottom: 0;
    width: 100%;
  }
  
  /* Ratings */
  
  .rating-outer {
    display: inline-block;
    position: relative;
    font-family: FontAwesome;
    color: #fdcc0d;
  }
  
  .rating-outer::before {
    content: '\f006 \f006 \f006 \f006 \f006';
  }
  
  .rating-inner {
    position: absolute;
    top: 0;
    left: 0;
    white-space: nowrap;
    overflow: hidden;
    width: 0;
  }
  
  .rating-inner::before {
    content: '\f005 \f005 \f005 \f005 \f005';
    color: #f8ce0b;
  }
  
  /* Product Details Page */
  #product_id {
    color: grey;
    font-size: 0.8rem;
  }
  
  #product_price {
    font-size: 2rem;
    font-weight: bold;
  }
  
  #cart_btn,
  #review_btn {
    border-radius: 2rem;
    background-color: #fa9c23;
    border: none;
    padding: 0.5rem 2rem;
  }
  
  #product_seller {
    color: grey;
    font-size: 0.9rem;
  }
  
  #stock_status {
    font-size: 1.1rem;
    font-weight: bold;
    margin-left: 0.3rem;
  }
  
  .redColor {
    color: red;
  }
  
  .greenColor {
    color: green;
  }
  
  #product_image {
    margin-top: 9rem;
  }
  
  /* Loader */
  .loader {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20%;
    width: 80px;
    height: 80px;
    padding-left: 0;
  }
  .loader:after {
    content: ' ';
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fa9c23;
    border-color: #fa9c23 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* Cart */
  
  .cart-item {
    margin: 1.8rem 0rem;
    padding: 0rem 0.7rem;
  }
  
  .cart-item a {
    color: grey;
  }
  
  #delete_cart_item {
    color: red;
    background: white;
    padding: 0.3rem 0.5rem;
    font-size: 1.1rem;
    border: none;
  }
  
  /* #card_item_price {
    color: #febd69;
    font-weight: bold;
    font-size: 1.4rem;
  } */
  
  #checkout_btn,
  .review-btn {
    background-color: #fa9c23;
    border-color: #fa9c23;
    margin-top: 2rem;
    border-radius: 5rem;
  }
  
  #view_order_details {
    background-color: #fa9c23;
    border-color: #fa9c23;
  }
  
  #order_summary {
    border: 1px solid #e3e3e3;
    border-radius: 1rem;
    padding: 2rem 1.5rem;
  }
  
  .order-summary-values {
    float: right;
    font-weight: bold;
  }
  
  .stockCounter input {
    border: none;
    width: 3rem;
    text-align: center;
  }
  
  .plus,
  .minus {
    padding: 0.1rem 0.5rem;
  }
  
  .stockCounter input::-webkit-outer-spin-button,
  .stockCounter input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  .form-control:disabled,
  .form-control[readonly] {
    background-color: white;
  }
  
  /* Login & Register */
  
  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
  
    font-weight: 700;
  }
  
  .wrapper form {
    padding: 2.5rem 3rem;
  }
  
  .wrapper form .btn {
    background-color: #fa9c23;
    border-color: #fa9c23;
    color: white;
    margin-top: 2.5rem;
  }
  
  .wrapper form a {
    font-size: 0.9rem;
    color: grey;
  }
  
  /* Avatar */
  .avatar {
    display: inline-block;
    margin-bottom: 0;
    height: 3rem;
    width: 3rem;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
  }
  
  .avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .rounded-circle {
    border-radius: 50% !important;
  }
  
  .btn.focus,
  .btn:focus {
    outline: 0;
    box-shadow: none;
  }
  
  /* Header User Profile */
  
  .avatar-nav {
    margin-right: 0.6rem;
    height: 2.2rem;
    width: 2.2rem;
  }
  
  /* Checkout Steps */
  
  .checkout-progress div {
    box-sizing: border-box;
  }
  
  .checkout-progress {
    display: block;
    clear: both;
    margin: 20px auto;
    width: auto;
    font-family: sans-serif;
    overflow: auto;
  }
  
  .step {
    margin: 0;
    border: 0;
    letter-spacing: 1px;
    line-height: 30px;
    padding: 5px 10px 5px 15px;
    color: grey;
    text-decoration: none;
    cursor: default;
    font-weight: bold;
    float: left;
    height: auto;
  }
  
  .incomplete {
    background: #eeeeee;
  }
  
  .active-step {
    background: #fa9c23;
    color: #fff;
  }
  
  .triangle-active {
    float: left;
    width: 0;
    border-top: 20px solid transparent;
    border-left: 15px solid #fa9c23;
    border-bottom: 20px solid transparent;
    margin-left: -1px;
  }
  
  .triangle2-active {
    width: 0;
    float: left;
    border-top: 20px solid #fa9c23;
    border-left: 15px solid #fff;
    border-bottom: 20px solid #fa9c23;
    margin-right: -1px;
  }
  
  .triangle-incomplete {
    float: left;
    width: 0;
    border-top: 20px solid transparent;
    border-left: 15px solid #eeeeee;
    border-bottom: 20px solid transparent;
    margin-left: -1px;
  }
  
  .triangle2-incomplete {
    width: 0;
    float: left;
    border-top: 20px solid #eeeeee;
    border-left: 15px solid #fff;
    border-bottom: 20px solid #eeeeee;
    margin-right: -1px;
  }
  
  /* User Profile */
  
  .avatar-profile {
    height: 16rem;
    width: 16rem;
  }
  
  .user-info h4 {
    font-weight: 800;
    color: grey;
    margin-top: 2rem;
  }
  
  .user-info p {
    font-size: 1.1rem;
  }
  
  #edit_profile {
    background-color: #fa9c23;
    border-color: #fa9c23;
  }
  
  /* Confirm Order */
  
  .order-confirm p,
  .order-details p {
    margin-left: 1.5rem;
  }
  
  .update-btn {
    background-color: #fa9c23;
    border-color: #fa9c23;
    color: white;
  }
  
  .update-btn:hover {
    color: white;
  }
  
  /* Pagination */
  .page-item.active .page-link {
    background-color: #fa9c23;
    border-color: #fa9c23;
  }
  
  .page-link {
    color: #fa9c23;
  }
  
  .page-link:hover {
    color: #fa9c23;
  }
  
  /* Admin Sidebar */
  
  .sidebar-wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
    min-height: 100vh;
    font-weight: normal;
  
    margin-top: 0;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 10;
  }
  #sidebar {
    min-width: 100%;
    max-width: 100%;
    background: #232f3e;
    color: #fff;
    transition: all 0.3s;
  }
  #sidebar.active {
    margin-left: -250px;
  }
  
  #sidebar .sidebar-header {
    padding: 20px;
    background: #232f3e;
  }
  #sidebar ul.components {
    padding: 5px 0px;
    border-bottom: 1px solid #232f3e;
  }
  
  #sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
    color: white;
    text-decoration: none;
  }
  #sidebar ul li a:hover {
    color: #232f3e;
    background: #fff;
  }
  
  #sidebar ul li div.dropdown-menu a{
    color: black;
  
  }
  
  #sidebar ul li a i {
    margin-right: 0.3rem;
  }
  
  #sidebar ul li.active > a,
  a[aria-expanded='true'] {
    color: #fff;
    background: #232f3e;
  }
  a[data-toggle='collapse'] {
    position: relative;
  }
  .dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
  
  ul ul a {
    font-size: 1rem !important;
    padding-left: 30px !important;
    background: #232f3e;
  }
  
  ul.CTAs {
    padding: 20px;
  }
  
  ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
  }
  
  /* Products List Admin */
  #products_table .btn {
    padding: 0.2rem 0.5rem;
    margin-left: 0.4rem;
  }
  
  /* Dashboard */
  
  .card-font-size {
    font-size: 1.5rem;
  }
  
  /* Reviews */
  
  .rating {
    margin-top: 10rem;
  }
  
  .stars {
    height: 100px;
    display: flex;
    align-items: center;
    padding-left: 0;
  }
  
  .star {
    display: inline;
    list-style: none;
    font-size: 3rem;
    padding-left: 0.9rem;
    color: #e3e3e3;
  }
  
  .star:first-child {
    padding-left: 0;
  }
  
  .orange {
    color: #fa9c23;
  }
  
  .yellow {
    color: #fdcc0d;
  }
  
  .review_user {
    font-size: 0.8rem;
    color: grey;
  }
  